import { ReactElement, useCallback, useMemo, useId } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import cn from "classnames";

import {
  FormTextInput,
  FormField,
  FormSelect,
  FormTextArea,
} from "oneclick-component/src/components/forms";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import {
  useEditTemplateMessageHandlerTemplateMessageTemplateMessageIdPostMutation as useEditTemplateMessage,
  useListTemplateMessageHandlerTemplateMessageGetQuery as useListTemplateMessage,
} from "oneclick-component/src/store/apis/enhancedApi";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { ToggleSwitch } from "oneclick-component/src/components/ToggleSwitch";
import AppRoutes from "../../routes/AppRoutes";
import { useShowError } from "../../hooks/useShowError";
import {
  EditTemplateMessageFormValues,
  editTemplateMessageFormSchema,
} from "./editTemplateMessageForm";

const EditTemplateMessageScreen = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();
  const templateMessageId = parseInt(id!, 10);
  const [editUser, { isLoading: isEditingUser }] = useEditTemplateMessage();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const { data } = useListTemplateMessage();
  const formDefault = useMemo(() => {
    if (data == null) {
      return { isActive: false };
    }
    for (const template of data.items) {
      if (template.id === templateMessageId) {
        return {
          slug: template.slug,
          externalId: template.externalId,
          body: template.body,
          config: JSON.stringify(template.config),
          isActive: template.isActive,
          type:
            template.type === "system"
              ? ("SYSTEM" as const)
              : ("USER" as const),
        };
      }
    }
    return { isActive: false };
  }, [data, templateMessageId]);

  const formId = useId();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditTemplateMessageFormValues>({
    resolver: zodResolver(editTemplateMessageFormSchema),
    defaultValues: formDefault,
  });

  const onValidSubmit = useCallback(
    async (data: EditTemplateMessageFormValues) => {
      try {
        await editUser({
          templateMessageId: templateMessageId,
          editTemplateMessageRequest: {
            slug: data.slug,
            body: data.body,
            config: data.config,
            externalId: data.externalId,
            seq: data.seq,
            isActive: data.isActive,
            type: data.type,
          },
        }).unwrap();
        showMessage({
          type: "success",
          title: t("templateMessage.edit.successDialog.title"),
          message: t("templateMessage.edit.successDialog.message"),
        });
      } catch (err: unknown) {
        console.error(err);
        showError(err);
      }
    },
    [editUser, showMessage, showError, t, templateMessageId]
  );

  return (
    <main className={cn("px-4", "pb-20", "md:px-0")}>
      <form
        id={formId}
        className={cn("flex", "flex-col", "gap-y-5")}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onValidSubmit)}
      >
        <FormTextInput
          name={"slug"}
          control={control}
          label={t("templateMessage.create.form.slug.label")}
          required={true}
          errorMessage={errors.slug?.message}
          placeholder={t("templateMessage.create.form.slug.placeholder")}
        />
        <FormTextInput
          name={"externalId"}
          control={control}
          label={t("templateMessage.create.form.externalId.label")}
          required={true}
          errorMessage={errors.externalId?.message}
          placeholder={t("templateMessage.create.form.externalId.placeholder")}
        />
        <FormTextArea
          name={"body"}
          control={control}
          rows={6}
          label={t("templateMessage.create.form.body.label")}
          required={true}
          errorMessage={errors.body?.message}
          placeholder={t("templateMessage.create.form.body.placeholder")}
        />
        <FormTextArea
          name={"config"}
          rows={4}
          control={control}
          label={t("templateMessage.create.form.config.label")}
          required={true}
          errorMessage={errors.config?.message}
          placeholder={t("templateMessage.create.form.config.placeholder")}
        />
        <FormSelect
          name={"type"}
          control={control}
          options={[
            {
              name: "SYSTEM",
              value: "SYSTEM",
            },
            {
              name: "USER",
              value: "USER",
            },
          ]}
          label={t("templateMessage.create.form.type.label")}
          required={true}
        />
        <FormField.Control name={"isActive"} control={control}>
          {({ field }) => (
            <ToggleSwitch
              label={t("templateMessage.create.form.isActive.label")}
              inputClassName={cn(
                "checked:bg-primary-500",
                "hover:checked:bg-primary-500",
                "focus:ring-primary-500",
                "focus:checked:bg-primary-500",
                "h-6",
                "!w-11",
                "after:ml-0.5",
                "after:mt-0.5",
                "checked:after:mt-0.5",
                "checked:after:ml-5.5",
                "focus:!ring-offset-4"
              )}
              labelClassName={cn(
                "!text-base",
                "!leading-6",
                "!text-black/86",
                "!font-normal"
              )}
              className={cn("flex", "items-center")}
              isChecked={field.value}
              onToggle={field.onChange}
            />
          )}
        </FormField.Control>
      </form>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={"mr-3"}
          to={AppRoutes.TemplateMessageListScreen.render()}
        >
          <Trans i18nKey="templateMessage.edit.footer.button.cancel" />
        </ButtonLink>
        <Button type="submit" form={formId} disabled={isEditingUser}>
          <Trans i18nKey="templateMessage.edit.footer.button.edit" />
        </Button>
      </footer>
    </main>
  );
};

export default EditTemplateMessageScreen;
