import z from "zod";

export const editTemplateMessageFormSchema = z.object({
  slug: z.string().trim().min(1).nullish(),
  externalId: z.string().trim().min(1).nullish(),
  body: z.string().trim().min(1).nullish(),
  config: z.string().trim().min(1).nullish(),
  isActive: z.boolean(),
  type: z.enum(["SYSTEM", "USER"]).nullish(),
  seq: z.number().nullish(),
});

export type EditTemplateMessageFormValues = z.infer<
  typeof editTemplateMessageFormSchema
>;
