import { ReactElement } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  RouteObject,
  Outlet,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import ProtectedRoute from "../components/ProtectedRoute";
import AuthCallbackScreen from "../screen/AuthCallbackScreen";

import AppRoutes from "./AppRoutes";
import ShiftListScreen from "../screen/ShiftListScreen";
import ShiftDetailScreen from "../screen/ShiftDetailScreen";
import UnauthorizedScreen from "../screen/UnauthorizedScreen";
import CreateMultiShiftScreen from "../screen/CreateMultiShiftScreen";
import CreateMultiShiftPreviewScreen from "../screen/CreateMultiShiftPreviewScreen";
import MultiShiftInvitationScreen from "../screen/MultiShiftInvitationScreen";
import PartTimeListScreen from "../screen/PartTimeListScreen/PartTimeListScreen";
import PublicHolidayScreen from "../screen/PublicHolidayScreen";
import {
  PartTimeListAvailabilityTab,
  PartTimeListWorkStatus418Tab,
} from "../screen/PartTimeListScreen";
import CreatePartTimeScreen from "../screen/CreatePartTimeScreen/CreatePartTimeScreen";
import LogInScreen from "../screen/LogInScreen/LogInScreen";
import EditPartTimeScreen from "../screen/EditPartTimeScreen";
import NotificationScreen from "../screen/NotificationScreen";
import NotFoundScreen from "oneclick-component/src/components/NotFoundScreen";
import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";
import EditShiftScreen from "../screen/EditShiftScreen";
import ActiveShiftTab from "../screen/ShiftListScreen/ActiveShiftTab";
import CancelledShiftTab from "../screen/ShiftListScreen/CancelledShiftTab";
import PendingNotificationTab from "../screen/NotificationScreen/PendingNotificationTab";
import HandledNotificationTab from "../screen/NotificationScreen/HandledNotificationTab";
import LogoutRedirectScreen from "../screen/LogoutRedirectScreen";
import AnnoucementScreen from "../screen/AnnouncementScreen";
import SuperAdminRoute from "../components/SuperAdminRoute";
import CreateUserScreen from "../screen/CreateUserScreen";
import EditUserScreen from "../screen/EditUserScreen";
import UserListScreen from "../screen/UserListScreen";
import WhatsappUsageScreen from "../screen/WhatsappUsageScreen";
import TemplateMessageListScreen from "../screen/TemplateMessageScreen";
import CreateTemplateMessageListScreen from "../screen/CreateTemplateMessageScreen";
import EditTemplateMessageListScreen from "../screen/EditTemplateMessageScreen";
import ExpiredShiftTab from "../screen/ShiftListScreen/ExpiredShiftTab";
import SendWelcomePtMessageScreen from "../screen/SendWelcomePtMessageScreen";
import ShiftRequestListScreen from "../screen/ShiftRequestListScreen";
import IncomingPartTimeTab from "../screen/ShiftRequestListScreen/IncomingPartTimeTab";
import OutgoingPartTimeTab from "../screen/ShiftRequestListScreen/OutgoingPartTimeTab";

const decoratedRoutesConfig: RouteObject[] = [
  {
    element: (
      <Sentry.ErrorBoundary fallback={<GenericErrorScreen />}>
        <Outlet />
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: AppRoutes.AuthCallbackScreen.path,
        element: <AuthCallbackScreen />,
      },
      {
        path: AppRoutes.LogoutRedirectScreen.path,
        element: <LogoutRedirectScreen />,
      },
      {
        path: AppRoutes.UnauthorizedScreen.path,
        element: <UnauthorizedScreen />,
      },
      {
        path: AppRoutes.LogInScreen.path,
        element: <LogInScreen />,
      },
    ],
  },
  {
    element: (
      <Sentry.ErrorBoundary fallback={<GenericErrorScreen />}>
        <ProtectedRoute>
          <Outlet />
        </ProtectedRoute>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: AppRoutes.AnnouncementScreen.path,
        element: <AnnoucementScreen />,
      },
      {
        path: AppRoutes.ShiftListScreen.path,
        element: <ShiftListScreen />,
        children: [
          {
            path: AppRoutes.ShiftListActiveShiftTab.path,
            element: <ActiveShiftTab />,
          },
          {
            path: AppRoutes.ShiftListExpiredShiftTab.path,
            element: <ExpiredShiftTab />,
          },
          {
            path: AppRoutes.ShiftListCancelledShiftTab.path,
            element: <CancelledShiftTab />,
          },
        ],
      },
      {
        path: AppRoutes.ShiftRequestListScreen.path,
        element: <ShiftRequestListScreen />,
        children: [
          {
            path: AppRoutes.ShiftRequestListIncomingPartTimeTab.path,
            element: <IncomingPartTimeTab />,
          },
          {
            path: AppRoutes.ShiftRequestListOutgoingPartTimeTab.path,
            element: <OutgoingPartTimeTab />,
          },
        ],
      },
      {
        path: AppRoutes.CreateMultiShiftScreen.path,
        element: <CreateMultiShiftScreen />,
      },
      {
        path: AppRoutes.CreateMultiShiftPreviewScreen.path,
        element: <CreateMultiShiftPreviewScreen />,
      },
      {
        path: AppRoutes.MultiShiftInvitationScreen.path,
        element: <MultiShiftInvitationScreen />,
      },
      {
        path: AppRoutes.EditShiftScreen.path,
        element: <EditShiftScreen />,
      },
      {
        path: AppRoutes.ShiftDetailScreen.path,
        element: <ShiftDetailScreen />,
      },
      {
        path: AppRoutes.PartTimeListScreen.path,
        element: <PartTimeListScreen />,
        children: [
          {
            path: AppRoutes.PartTimeListAvailabilityTab.path,
            element: <PartTimeListAvailabilityTab />,
          },
          {
            path: AppRoutes.PartTimeListWorkStatus418Tab.path,
            element: <PartTimeListWorkStatus418Tab />,
          },
        ],
      },
      {
        path: AppRoutes.CreatePartTimeScreen.path,
        element: <CreatePartTimeScreen />,
      },
      {
        path: AppRoutes.EditPartTimeScreen.path,
        element: <EditPartTimeScreen />,
      },
      {
        element: <NotificationScreen />,
        children: [
          {
            path: AppRoutes.PendingNotificationTab.path,
            element: <PendingNotificationTab />,
          },
          {
            path: AppRoutes.HandledNotificationTab.path,
            element: <HandledNotificationTab />,
          },
        ],
      },
    ],
  },
  {
    element: (
      <Sentry.ErrorBoundary fallback={<GenericErrorScreen />}>
        <ProtectedRoute>
          <SuperAdminRoute>
            <Outlet />
          </SuperAdminRoute>
        </ProtectedRoute>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: AppRoutes.UserListScreen.path,
        element: <UserListScreen />,
      },
      {
        path: AppRoutes.CreateUserScreen.path,
        element: <CreateUserScreen />,
      },
      {
        path: AppRoutes.EditUserScreen.path,
        element: <EditUserScreen />,
      },
      {
        path: AppRoutes.SendWelcomePtMessageScreen.path,
        element: <SendWelcomePtMessageScreen />,
      },
      {
        path: AppRoutes.PublicHolidayScreen.path,
        element: <PublicHolidayScreen />,
      },
      {
        path: AppRoutes.WhatsappUsageScreen.path,
        element: <WhatsappUsageScreen />,
      },
      {
        path: AppRoutes.TemplateMessageListScreen.path,
        element: <TemplateMessageListScreen />,
      },
      {
        path: AppRoutes.CreateTemplateMessageListScreen.path,
        element: <CreateTemplateMessageListScreen />,
      },
      {
        path: AppRoutes.EditTemplateMessageListScreen.path,
        element: <EditTemplateMessageListScreen />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundScreen />,
  },
];

export const RoutesConfig = Object.freeze(decoratedRoutesConfig);

const router = createBrowserRouter(decoratedRoutesConfig, {
  basename: "/",
});

const AppRouter = (): ReactElement => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
