interface AppRoute<Args extends unknown[] = any[]> {
  path: string;
  render?: (...args: Args) => string;
}
const asAppRouteTypes = <T extends { [K in keyof T]: AppRoute }>(et: T): T =>
  et;

const AppRoutes = asAppRouteTypes(
  Object.freeze({
    LogInScreen: {
      path: "/",
      render: () => "/",
    },
    AuthCallbackScreen: {
      path: "/auth_callback",
      render: () => "/auth_callback",
    },
    LogoutRedirectScreen: {
      path: "/logout_redirect",
      render: () => "/logout_redirect",
    },
    UnauthorizedScreen: {
      path: "/unauthorized",
      render: () => "/unauthorized",
    },
    CreateMultiShiftScreen: {
      path: "/master-shifts/create",
      render: () => "/master-shifts/create",
    },
    CreateMultiShiftPreviewScreen: {
      path: "/master-shifts/create/preview",
      render: () => "/master-shifts/create/preview",
    },
    MultiShiftInvitationScreen: {
      path: "/master-shifts/:id/invite",
      render: (id: number) => `/master-shifts/${id}/invite`,
    },
    EditShiftScreen: {
      path: "/shifts/:id/edit",
      render: (id: number) => `/shifts/${id}/edit`,
    },
    ShiftDetailScreen: {
      path: "/shifts/:id",
      render: (id: number) => `/shifts/${id}`,
    },
    PartTimeListScreen: {
      path: "/part-time-users",
      render: () => "/part-time-users",
    },
    CreatePartTimeScreen: {
      path: "/part-time-users/create",
      render: () => "/part-time-users/create",
    },
    EditPartTimeScreen: {
      path: "/part-time-users/edit/:id",
      render: (id: number) => `/part-time-users/edit/${id}`,
    },
    ShiftListScreen: {
      path: "/shifts",
      render: () => "/shifts",
    },
    ShiftListActiveShiftTab: {
      path: "/shifts/active",
      render: () => "/shifts/active",
    },
    ShiftListExpiredShiftTab: {
      path: "/shifts/expired",
      render: () => "/shifts/expired",
    },
    ShiftListCancelledShiftTab: {
      path: "/shifts/cancelled",
      render: () => "/shifts/cancelled",
    },
    ShiftRequestListScreen: {
      path: "/shift-requests",
      render: () => "/shift-requests",
    },
    ShiftRequestListIncomingPartTimeTab: {
      path: "/shift-requests/incoming",
      render: () => "/shift-requests/incoming",
    },
    ShiftRequestListOutgoingPartTimeTab: {
      path: "/shift-requests/outgoing",
      render: () => "/shift-requests/outgoing",
    },
    PartTimeListAvailabilityTab: {
      path: "/part-time-users/availability",
      render: () => "/part-time-users/availability",
    },
    PartTimeListWorkStatus418Tab: {
      path: "/part-time-users/work-status-418",
      render: () => "/part-time-users/work-status-418",
    },
    PublicHolidayScreen: {
      path: "/public-holidays",
      render: () => "/public-holidays",
    },
    PendingNotificationTab: {
      path: "/notifications/pending",
      render: () => "/notifications/pending",
    },
    NormalNotificationTab: {
      path: "/notifications/normal",
      render: () => "/notifications/normal",
    },
    HandledNotificationTab: {
      path: "/notifications/handled",
      render: () => "/notifications/handled",
    },
    AnnouncementScreen: {
      path: "/announcements",
      render: () => "/announcements",
    },
    UserListScreen: {
      path: "/users",
      render: () => "/users",
    },
    TemplateMessageListScreen: {
      path: "/template-messages",
      render: () => "/template-messages",
    },
    CreateTemplateMessageListScreen: {
      path: "/template-messages/create",
      render: () => "/template-messages/create",
    },
    EditTemplateMessageListScreen: {
      path: "/template-messages/edit/:id",
      render: (id: number) => `/template-messages/edit/${id}`,
    },
    WhatsappUsageScreen: {
      path: "/usage",
      render: () => "/usage",
    },
    CreateUserScreen: {
      path: "/users/create",
      render: () => "/users/create",
    },
    EditUserScreen: {
      path: "/users/edit/:id",
      render: (id: number) => `/users/edit/${id}`,
    },
    SendWelcomePtMessageScreen: {
      path: "/send-welcome-pt-message",
      render: () => "/send-welcome-pt-message",
    },
  })
);

export default AppRoutes;
