import React, { ReactElement, useEffect } from "react";
import cn from "classnames";
import { ButtonLink } from "oneclick-component/src/components/Button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Trans } from "react-i18next";
import AppRoutes from "../../routes/AppRoutes";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import {
  useListTemplateMessageHandlerTemplateMessageGetQuery as useTemplateMessageList,
  TemplateMessage,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useShowError } from "../../hooks/useShowError";

interface TemplateMessageItemProps {
  templateMessage: TemplateMessage;
}

const TemplateMessageItem = React.memo(
  (props: TemplateMessageItemProps): ReactElement => {
    const { templateMessage } = props;

    return (
      <div className={cn("flex", "items-center", "border", "p-4", "shadow")}>
        <div
          className={cn("flex", "flex-1", "flex-col", "gap-1", "mr-7", {
            "opacity-20": !templateMessage.isActive,
          })}
        >
          {!templateMessage.isActive ? (
            <p>
              <Trans i18nKey="templateMessage.list.item.notActive" />
            </p>
          ) : null}
          <p>{`${templateMessage.slug}: ${templateMessage.externalId}`}</p>
          <pre className={cn("bg-slate-200", "rounded-md", "p-3")}>
            {templateMessage.body}
          </pre>
        </div>
        <ButtonLink
          theme={"primary"}
          className={"mr-3"}
          to={AppRoutes.EditTemplateMessageListScreen.render(
            templateMessage.id
          )}
        >
          <Trans i18nKey="templateMessage.list.item.button.edit" />
        </ButtonLink>
      </div>
    );
  }
);

interface TemplateMessageListScreenContentProps {
  data: TemplateMessage[];
}
const TemplateMessageListScreenContent = (
  props: TemplateMessageListScreenContentProps
): ReactElement => {
  const { data } = props;

  return (
    <div className={cn("space-y-3", "relative", "mt-10")}>
      <div className={cn("pb-24", "space-y-3", "sm:space-y-5")}>
        {data.length > 0 ? (
          data.map((u) => (
            <TemplateMessageItem key={u.id} templateMessage={u} />
          ))
        ) : (
          <Trans i18nKey="templateMessage.list.empty" />
        )}
      </div>
    </div>
  );
};

const TemplateMessageListScreen = (): ReactElement => {
  const { showError } = useShowError();

  const {
    data,
    isFetching,
    error: templateMessageListError,
  } = useTemplateMessageList();

  useEffect(() => {
    if (templateMessageListError != null) {
      showError(templateMessageListError);
    }
  }, [templateMessageListError, showError]);

  return (
    <main
      className={cn(
        "rounded-lg",
        "bg-white",
        "sm:shadow",
        "sm:mx-auto",
        "px-4",
        "pb-5",
        "sm:p-6"
      )}
    >
      <div className={cn("flex", "flex-row", "items-center")}>
        <div
          className={cn(
            "flex-1",
            "text-2xl",
            "flex",
            "flex-row",
            "items-center"
          )}
        >
          <span className={cn("font-bold", "mr-2")}>
            <Trans i18nKey="templateMessage.list.title" />
          </span>
        </div>
        <ButtonLink
          theme="white"
          prefixIcon={PlusIcon}
          to={AppRoutes.CreateTemplateMessageListScreen.render()}
          className="text-sm"
        >
          <Trans i18nKey="templateMessage.list.button.addTemplateMessage" />
        </ButtonLink>
      </div>
      <div
        className={cn("gap-y-3", "sm:gap-y-5", "h-full", "flex", "flex-col")}
      >
        {isFetching ? (
          <LoadingScreen />
        ) : (
          <TemplateMessageListScreenContent data={data?.items ?? []} />
        )}
      </div>
    </main>
  );
};

export default TemplateMessageListScreen;
