import z from "zod";

export const createTemplateMessageFormSchema = z.object({
  slug: z.string().trim().min(1),
  externalId: z.string().trim().min(1),
  body: z.string().trim().min(1),
  config: z.string().trim().min(1),
  isActive: z.boolean(),
  type: z.enum(["SYSTEM", "USER"]),
  seq: z.number().optional(),
});

export type CreateTemplateMessageFormValues = z.infer<
  typeof createTemplateMessageFormSchema
>;
